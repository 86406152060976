import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import './MachinesDetails.css';

const MachinesDetails = () => {
    const tragos = useSelector((state) => state.tragos);
    const machineStatus = useSelector((state) => state.machineStatus); // Estado de la máquina
    const { id } = useParams();
    const navigate = useNavigate();

    // Determinar si la máquina está online u offline
    const isOnline = machineStatus[id]?.online ?? false; // Asume que `machineStatus` es un objeto con ID de máquinas.

    return (
        <div className="details-container">
            {/* Botón de flecha para volver */}
            <button className="back-button" onClick={() => navigate(-1)}>
                ← Volver
            </button>

            <h2>Detalles de la Máquina - ID: {id}</h2>

            {/* Indicador de estado */}
            <div className={`machine-status ${isOnline ? 'online' : 'offline'}`}>
                Estado: {isOnline ? 'Online' : 'Offline'}
            </div>

            <div className="tragos-list">
                {tragos.length > 0 ? (
                    tragos.map((trago, index) => (
                        <div key={trago._id} className="trago-card">
                            <h3>Trago #{index + 1}</h3>
                            <p><strong>Fernet:</strong> {trago.mlFernet} ml</p>
                            <p><strong>Coca-Cola:</strong> {trago.mlCocaCola} ml</p>
                            <p>
                                <strong>Fecha:</strong> {new Date(trago.fecha).toLocaleDateString()} - {new Date(trago.fecha).toLocaleTimeString()}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No hay tragos registrados para esta máquina.</p>
                )}
            </div>
        </div>
    );
};

export default MachinesDetails;
