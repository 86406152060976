import axios from 'axios';
import { 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    GET_MACHINE,
    GET_DRINKS,
    GET_MACHINE_STATUS 
        } from './actions-types';


        export const getMachineStatus = (mac) => async (dispatch) => {
            try {
                const response = (await axios.get(`/status/${mac}`)).data;
        
                dispatch({
                    type: GET_MACHINE_STATUS,
                    payload: { mac, status: response.status },
                });
            } catch (error) {
                console.error('Error fetching machine status:', error);
            }
        };


export const getMachines = () => async (dispatch)=> {
    try {
       const useName = localStorage.getItem('userName');
       const response = (await axios.get(`/api/drinks/machines/${useName}`)).data;
       dispatch({
        type: GET_MACHINE,
        payload: response
       })  
    } catch (error) {
        
    }
}


export const getDrinks = (mac) => async(dispatch) => {
    console.log(mac);
    
    try {
        const response = (await axios.get(`/api/drinks/machine/${mac}`)).data;
        dispatch({
            type: GET_DRINKS,
            payload: response
        })
    } catch (error) {
        
    }
}

export const registerUser = ( userName, password) => async (dispatch) => {
    try {
        const res = (await axios.post('/api/user/register', { userName, password })).data;
            localStorage.setItem('token', res.token);
            localStorage.setItem('userName', res.userName);
            
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res,
        });
    } catch (error) {
        console.error(error.response.message);
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.message,
        });
    }
}

export const loginUser = (userName, password) => async (dispatch) => {
    try {
        
        const res = await axios.post('/api/user/login', { userName, password });
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userName', res.data.userName);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });
    } catch (error) {
        console.error(error.response.data.message);
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message,
        });
    }
};


export const logout = () => (dispatch) => {    
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    dispatch({
        type: LOGOUT,
    });
};
