import React from 'react';
import './Dashboard.css';
import MachinePerformance from '../machinesPerformance/MachinesPerformance';


const Home = () => {
    
const userName = localStorage.getItem('userName')
    return (
       <>
        { localStorage.getItem('userName') !== null ? <div className="dashboard-container">
            <h1>Dashboard de {userName}</h1>
            <MachinePerformance/>
        </div> : <h1>no tienes acceso</h1> }
       </>
    ) 
};

export default Home;
