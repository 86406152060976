import React from 'react';
import './Footer.css'; // Importamos los estilos

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Fernet Machine. Todos los derechos reservados.</p>
            <ul className="footer-links">
                <li><a href="https://facebook.com">Facebook</a></li>
                <li><a href="https://twitter.com">Twitter</a></li>
                <li><a href="https://instagram.com">Instagram</a></li>
            </ul>
        </footer>
    );
};

export default Footer;
