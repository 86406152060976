import { 
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    GET_MACHINE,
    GET_DRINKS,
    GET_MACHINE_STATUS 
 } from './actions-types'

// // Recuperar token desde localStorage si existe
// const token = localStorage.getItem('token');

const initialState = {
    isAuthenticated: false,
    user: null,
    loading: true,
    error: null,
    machines: {},
    tragos: {},
    machineStatus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MACHINE_STATUS:
            return {
                ...state,
                machineStatus: {
                    ...state.machineStatus,
                    [action.payload.mac]: action.payload.status,
                },
            };
        case GET_DRINKS:
            return {
                ...state,
                tragos: action.payload,
            };
        case GET_MACHINE: 
            return{
                ...state,
                machines: action.payload,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                loading: true
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
