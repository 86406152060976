// Navbar.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; 
import logoName from '../../img/flashpe.png';
import logoAnimado from '../../img/flashpe.mp4';
import { logout } from '../../redux/actions';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
    const userName = localStorage.getItem('userName');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        if (localStorage.getItem('userName') === null) {
            navigate('/');
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                {/* Aquí cambiamos img por video */}
                <video className="logo" src={logoAnimado} autoPlay loop muted playsInline />
            </div>
            {
                localStorage.getItem('userName') != null ? (
                    <ul className="navbar-links">
                        <li><a href='/machines'>Machines</a></li>
                        <li><a href="/home">{userName}</a></li>
                        <button className='logout-button' onClick={handleLogout}>
                            <i className='fas fa-power-off'></i>
                        </button>
                    </ul>
                ) : <></>
            }
        </nav>
    );
};

export default Navbar;
