import { createStore, applyMiddleware, compose } from "redux";
import reducer from "./reducer";
import { thunk } from "redux-thunk"; // Cambiar a importación nombrada

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Conexión con Redux DevTools y middleware thunk
const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;
