import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './MachinePerformance.css';  
import axios from '../../../src/axiosConfig'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MachinePerformance = () => {
    const [performanceData, setPerformanceData] = useState([]);
    const [timeInterval, setTimeInterval] = useState('monthly');
    const [userName, setUserName] = useState('john_doe'); 

    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                const userName = localStorage.getItem('userName')
                const response = await axios.get(`https://new-back-cuenta-taragos.vercel.app/api/performance/${userName}`,
                    { headers: {'Cache-Control': 'no-cache', 'Pragma': 'no-cache'}}
                );
                console.log("log local data: ", response.data);
                
                setPerformanceData(response.data);
            } catch (error) {
                console.error("Error fetching performance data: ", error);
            }
        };
        fetchPerformanceData();
    }, [userName]); 

    const handleTimeIntervalChange = (e) => {
        setTimeInterval(e.target.value);
    };

    return (
        <div className="table-container">
            <h2>Rendimiento de las Máquinas</h2>
            <label>
                Ver datos por:
                <select onChange={handleTimeIntervalChange} value={timeInterval}>
                    <option value="monthly">Mensual</option>
                    <option value="weekly">Semanal</option>
                </select>
            </label>
            
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>MAC</th>
                        <th>Total Tragps</th>
                        <th>Total Ingresos</th>
                    </tr>
                </thead>
                <tbody>
                    {performanceData.map(machine => (
                        <tr key={machine.id}>
                            <td>{machine.nombre}</td>
                            <td>{machine.mac}</td>
                            <td>{machine.totalTragos}</td>
                            <td>${machine.totalIngresos}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {performanceData.map(machine => (
                <div key={machine.id} className="chart-container">
                    <h3>{machine.nombre} - Rendimiento {timeInterval === 'monthly' ? 'Mensual' : 'Semanal'}</h3>
                    <Line
                        data={{
                            labels: machine[`${timeInterval}Performance`].map(data => data.fecha),
                            datasets: [
                                {
                                    label: 'Cantidad de Tragos',
                                    data: machine[`${timeInterval}Performance`].map(data => data.cantidad),
                                    borderColor: 'rgba(75,192,192,1)',
                                    fill: false,
                                },
                                {
                                    label: 'Ingresos ($)',
                                    data: machine[`${timeInterval}Performance`].map(data => data.ingresos),
                                    borderColor: 'rgba(153,102,255,1)',
                                    fill: false,
                                },
                            ],
                        }}
                        options={{
                            scales: {
                                x: { title: { display: true, text: 'Fecha' } },
                                y: { title: { display: true, text: 'Valor' } },
                            },
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default MachinePerformance;
